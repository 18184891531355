import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../../components/seo"
import AnimationWrapper from "../../components/animationWrapper"
import ContactSection from "../../components/contactSection"
import Container from "../../components/container"
import Header from "../../components/header"
import IconSection from "../../components/iconSection"
import Infographic from "../../components/infographic"
import ProductTilesSection from "../../components/productTilesSection"
import TextSection from "../../components/textSection"
import Layout from "../../components/layout"

import "./produkty.css"

const KsztaltkiPage = ( {data, location} ) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Zobacz!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      {data.page.frontmatter.text_section_main.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_main} />
          </AnimationWrapper>
        </Container>
      }

      <section id="ksztaltki-stropowe-section" className="page-section">
        <Container>
          <AnimationWrapper animationType="fadeIn">
            {/* <Title title="Kształtki stropowe" /> */}
            <IconSection data={data.page.frontmatter} image={data.iconsZaletyKsztaltkiImg} location="homepage-keramzyt" />
          </AnimationWrapper>
        </Container>
      </section>

      {data.page.frontmatter.text_section_secondary.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_secondary} />
          </AnimationWrapper>
        </Container>
      }

      <section id="klb-l-tiles-section" className="product-tiles-section">
        <ProductTilesSection data={data.KsztaltkiTiles} location="ksztaltki" />
      </section>

      <section id="infographic-dobre-na-start" className="infographic-section page-section">
        <Container>
          <AnimationWrapper className="infographic-wrapper">
            <Infographic data={data.infographicDobreNaStart} name="dobre-na-start" />
            <Infographic data={data.infographicSkutecznaIzolacja} name="skuteczna-izolacja" />
          </AnimationWrapper>
        </Container>
      </section>

      {data.page.frontmatter.text_section_bottom.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_bottom} />
          </AnimationWrapper>
        </Container>
      }

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "ksztaltki" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        icons_section_title
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_secondary{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        text_section_bottom{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
      }
    }
    iconsZaletyKsztaltkiImg: file(
      relativePath: {eq: "ksztaltki-stropowe-grey.png"}
    ){
      id
      publicURL
      childImageSharp {
        gatsbyImageData(
          transformOptions: {fit: COVER},
          webpOptions: {quality: 95}
        )
      }
      relativePath
    }
    KsztaltkiTiles: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        internal: {contentFilePath: {regex: "/(products)/"}},
        frontmatter: {category: {eq: "ksztaltki"}}
      }
    ){
      nodes {
        frontmatter {
          name
          slug
          subtitle
          desc_short
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
          image_second {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
        }
        id
        body
      }
    }
    infographicDobreNaStart: mdx(internal: {contentFilePath: {regex: "/(infographic-sections/ksztaltki/dobre-na-start)/"}}) {
      frontmatter {
        title
        title_color
        type
        bg_color
        image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
      }
      body
    }
    infographicSkutecznaIzolacja: mdx(internal: {contentFilePath: {regex: "/(infographic-sections/ksztaltki/skuteczna-izolacja)/"}}) {
      frontmatter {
        title
        title_color
        type
        bg_color
        image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
      }
      body
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }

`

export default KsztaltkiPage
