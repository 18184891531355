import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import Title from "./title"
import "./infographic.css"

const Infographic = ( { data, name } ) => {

    return (
      <div className={`${name}-infographic infographic-container`}>

        <div className="image-container">
          <GatsbyImage
            image={data.frontmatter.image.childImageSharp.gatsbyImageData}
            alt={data.frontmatter.title}
          />
        </div>

        <div className="description-container">
          <Title className="infographic-title" title={data.frontmatter.title} />
          <p className="infographic-desc">
            {data.body}
          </p>
        </div>

      </div>
    )
  }

export default Infographic